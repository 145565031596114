<!--  -->
<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="table">
    <el-table class="common-table" :data="tableList" border>
      <el-table-column label="类型名称" prop="workTypeName">
      </el-table-column>
      <el-table-column prop="typeDescribe" label="描述">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.typeDescribe" placement="right">
            <div style="white-space: nowrap;white-space: nowrap;text-overflow: ellipsis;overflow: hidden">{{ scope.row.typeDescribe }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName == 'third'" prop="isExpenditureName" label="关联信息">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center;">
            <img v-show="scope.$index == 0" class="colImg noClick" src="~@/assets/images/up0.png"/>
            <img @click="() => {sortUp(scope.row, scope.$index)} " v-show="scope.$index" class="colImg" src="~@/assets/images/up.png" />
            <img v-show="tableData.length - (scope.$index + 1) == 0" class="colImg2 noClick" src="~@/assets/images/down0.png" />
            <img @click="() => {sortDown(scope.row, scope.$index)}" v-show="tableData.length - (scope.$index + 1) != 0" class="colImg2"  src="~@/assets/images/down.png" />
            <el-button v-if="comId == scope.row.companyId" @click=" () => { changeDialogTit(scope.row) }" type="text" >编辑</el-button>
            <el-popconfirm v-if="comId == scope.row.companyId" title="确定删除吗？" @confirm="() => deleteShow(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否应用到公司">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isUseCompany"
            :active-value="1"
            :inactive-value="0"
            active-color="#1890ff"
            inactive-color="#dbdbdb"
            @change="changeShow(scope.row)"
            >
          </el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSize"
      :page-size="pageSize"
      :current-page="pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <!-- <el-dialog v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-dialogDrag title="删除报表" class="common-dialog-isOk" :append-to-body="true" width="400px" :visible.sync="deleteVisible">
      <div>
        确认删除该报表吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="deleteVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {reportDelete()}">确 定</el-button>
      </span>
    </el-dialog> -->

    <!-- 捞取弹窗 -->
    <el-dialog v-dialogDrag :append-to-body="true" :visible.sync="updateDialogVisble" width="520px">
      <template slot="title"><div style="display:flex;align-items: center;"><img src="~@/assets/images/if-exclamation@1x.png" class="common-diaimgs"><span class="common-diatit">提醒</span> </div></template>
      <div class="common-diacon">当前步骤中已设置指定人员，不可应用到公司<br/>指定人员是否立即更新为发起人自选</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="updateDialogVisble = false;tableRow.isUseCompany = 0;">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {updateApproveType()}">更 新</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { tableColumn, Config } from "../../../utils/index.js";
import { updateWorkOrderById,updateApproveType } from "../../../service/workOrder.js"
import { mapState } from "vuex";

export default {
  props: ["tableData","activeName","childPagination","paramsPageNum","paramsPageSize"],
  data() {
    return {
      loading: false,
      tableColumn,
      adminId:  this.$sto.get(Config.constants.userInfo).user_id,
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      deleteVisible: false,
      deleteId: '',
      sort: null,
      updateDialogVisble: false,
      tableRow: {},
    };
  },
  computed: {
    ...mapState(["comId"]),
    tableList(){
      return this.tableData
    },
    pagination(){
      return this.childPagination
    },
    pageNum(){
      return this.paramsPageNum
    },
    pageSize(){
      return this.paramsPageSize
    }
  },
  mounted() {
  },
  methods: {
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.$emit("changes","size",e)
      } else {
        this.$emit("changes","num",e)
      }
      this.$emit('updataList')
    },
    async deleteShow(id) {
      let params = {
        id: id,
        deleteFlag: 1
      }
      const res = await updateWorkOrderById(params)
      if(res.code != 200){
        return this.$message.error("删除失败")      
        }
        this.$message.success("删除成功")
        this.$emit('updataList')
    },
    // 上
    async sortUp(row, i){
      if(i == 0){
        this.$message.warning("已经是第一个啦")
      }else{
        let workList = this.tableList
        let temp = workList[i - 1] //上一条数据 
        let lastSort = temp.sort //上一条sort

        let params = {
          id: row.id,
          sort: lastSort
        }
        await updateWorkOrderById(params)
        let params2 = {
          id: temp.id,
          sort: row.sort
        }
        const res = await updateWorkOrderById(params2)
        if(res.code == 200){
          this.$message.success("操作成功")
        }
      }
      this.$emit('updataList')
    },
    // 下
    async sortDown(row,i){
      let workList = this.tableList
      if (i == (workList.length - 1)) {
        this.$message({
          message: '已经是最后一个啦',
          type: 'warning'
        })
      } else {
        let temp = workList[i + 1] //下一条数据 
        let upSort = temp.sort //上一条sort

        let params = {
          id: row.id,
          sort: upSort
        }
        await updateWorkOrderById(params)
        let params2 = {
          id: temp.id,
          sort: row.sort
        }
        const res = await updateWorkOrderById(params2)
        if(res.code == 200){
          this.$message.success("操作成功")
        }
      }
      this.$emit('updataList')
    },
    routeFun(url) {
      this.$router.push(url)
    },
    // 编辑
    changeDialogTit(row) {
      if(this.activeName == 'second'){ //审批--编辑
        this.$router.push({path: '/work-order/new-examine',query:{id: row.id,name: 'edit'}})
      }else if(this.activeName == 'first'){ //新建---编辑
        this.$router.push({path: '/work-order/edit',query:{id: row.id}})
      }else {
        this.$router.push({path: '/work-order/scene',query:{id: row.id,name: 'edit'}})
      }
    },
    async changeShow(row) {
      this.tableRow = row
      if(row.approveType=='2'){
        return this.updateDialogVisble = true
      }
      let params = {
        id: row.id,
        isUseCompany: row.isUseCompany
      };
      const res = await updateWorkOrderById(params);
      if (res.code != 200) {
        return this.$message.error("操作失败");
      }
      this.$message.success("操作成功");
      this.$emit('updataList')
    },
    async updateApproveType(){ //更新
      const res = await updateApproveType({workOrderId: this.tableRow.id})
      if(res.code == 200){
        let params = {
          id: this.tableRow.id,
          isUseCompany: this.tableRow.isUseCompany
        };
        const res = await updateWorkOrderById(params);
        if (res.code != 200) {
          return this.$message.error("操作失败");
        }
        this.$message.success("操作成功");
        this.updateDialogVisble = false
        this.$emit('updataList')
      }
    }
  },
};
</script>

<style lang="less" scoped>
.table {
  .table{
    // border: none;
    border-radius: 0.02083rem;
    width: 98%;
    margin: 0.08333rem auto;
  }
  /deep/ .el-table--border::after, 
  .el-table--group::after{
    height: 0;
  }
  .colImg {
    margin-right: 8px;
    cursor: pointer;
  }
  .colImg2 {
    margin-right: 26px;
    cursor: pointer;
  }
  .noClick {
    cursor: auto;
  }
  /deep/.el-popover__reference-wrapper{
    margin-left: 10px;
  }
  
}
/deep/ .el-table__header{
    th{
      background-color: #F7F7F7 !important;
    }
}
</style>